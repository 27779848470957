import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import ArticlesReducers from "./articleReducers";
import AuthReducer from "./authReducers";
import CommentsReducer from "./commentsReducers";
import DashboardReducer from "./dashboardReducers";
import BookmarkReducer from "./bookmarkReducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  articles: ArticlesReducers,
  comments: CommentsReducer,
  dashboard: DashboardReducer,
  bookmark: BookmarkReducer
});

export default persistReducer(persistConfig, rootReducer);