import React, {useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Typography, Button, TextField, Autocomplete, Chip } from '@mui/material';
import Editor from "../../components/editor/Editor";
import { CreateArticle } from '../../redux/actions/articlesActions';
import { GetMySpecificArticle, UpdateMyArticle } from '../../redux/actions/dashboardActions';

const CreatePage = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state.dashboard);
    const user = sessionStorage.getItem("user");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);
    const [blocks, setBlocks] = useState([]);

    useEffect(() => {
      setTitle("");
      console.log(params);
      console.log(location.pathname.split("/")[1]);
      if(location.pathname.split("/")[1] === "edit"){
        // edit article
        dispatch(GetMySpecificArticle(params.id, setLoading))
      }else {

      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      try {
        if(location.pathname.split("/")[1] === "edit"){
          console.log(state.mySpecificArticle);
          setTitle(state.mySpecificArticle.title || "");
          setTags(state.mySpecificArticle.tags || []);
          setBlocks(state.mySpecificArticle.blocks || [])
        }
      } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const updateArticle = (data) => {
      console.log(data)
      console.log(tags);
      const post = {
        title: title || "default",
        author : JSON.parse(user).result._id,
        tags: tags,
        blocks: data
      }
      dispatch(UpdateMyArticle(state.mySpecificArticle?._id, post, setLoading))
        .then(() => navigate(-1))
    }

    const editorState = (data) => {
      console.log(data)
      console.log(tags);
      const post = {
        title: title || "default",
        author : JSON.parse(user).result._id,
        tags: tags,
        blocks: data
      }
      dispatch(CreateArticle(post, setLoading))
        .then((res) => navigate(`/${res.slug}`))
    }

    return (
      <Box>
        <Container maxWidth="lg">
          <Box mb={3}>
            <Typography fontSize={30} fontWeight={600} color="#bdbdbd"> <i>-----TITLE-----</i> </Typography>
            <TextField 
              fullWidth 
              multiline
              label="Title" 
              id="fullWidth"
              size='small'
              value={title}
              onChange={(e) => setTitle(e.target.value)} />
          </Box>
          <Box mb={3}>
            <Typography fontSize={30} fontWeight={600} color="#bdbdbd"> <i>-----TAGS-----</i> </Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              freeSolo
              options={[]}
              value={tags}
              onChange={(event, newValue) => setTags(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  placeholder="Enter relevent tags"
                />
              )}
            />
          </Box>
          {
            location.pathname.split("/")[1] === "edit"
            ?
            <Editor 
              readMode={false} 
              readData={blocks} 
              returnData={(data) => updateArticle(data)}
              loading={loading} />
            :
            <Editor 
              readMode={false} 
              readData={null} 
              returnData={(data) => editorState(data)}
              loading={loading} />
          }
        </Container>
      </Box>
  )
}

export default CreatePage;