import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      backgroundColor: "#f3f3f3",
    },
    minHeight: "calc(100vh - 60px)"
  },

  profile__topPanel: {
    backgroundColor: "#ffffff",
    minHeight: "300px",
    height: "20px",
    [theme.breakpoints.up('md')]: {
      borderRadius: "12px",
      // border: "solid 1px #d4d4d4",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
    },
  },

  content: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      padding: "24px",
      borderRadius: "12px",
      // border: "solid 1px #d4d4d4",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
    },
  },

  bottomLinks: {
    border: "solid",
    borderWidth: "0px",
    borderBottomWidth: "5px",
    borderColor: "#19857b",
    margin: "0px 10px",
    cursor: "pointer"
  }

}));

export default useStyles;