import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Divider,
  Typography,
  Container,
  Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { DeleteBookmark, GetMyBookmarks } from '../../redux/actions/bookmarkActions';

import BookmarkDeleteModal from './BookmarkDeleteModal';

import useStyles from './styles';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


const BookmarkPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector(state => state.bookmark);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [bookmarksList, setBookmarksList] = useState([]);
  const [bookmarksTotal, setBookmarksTotal] = useState(0);

  const [deleteId, setDeleteId] = useState("");

  // deleteModals
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  // ---------------- table pagination ------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };   


  useEffect(() => {
    dispatch(GetMyBookmarks(setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page, rowsPerPage]);

  useEffect(() => { 
    try {
      console.log(state);
      formatBookmarks(state.myBookmarks || []);
      setBookmarksTotal(state.myBookmarks.Total || 0);
    } catch (err) {}
  }, [state]);


  // ------------------ formatters --------------------
  const formatBookmarks = (list) => {
    setBookmarksList(list);
  };
  

  // ----------------- delete handler ----------------
  const handleRemoveBookmark = () => {
    setUpdating(true);
    dispatch(DeleteBookmark(deleteId)).then(() => {
      dispatch(GetMyBookmarks(setUpdating)).then(() => {
        setDeleteModalOpen(false);
        setUpdating(false);
        setDeleteId("");
      })
    });
  };

  return (
    <Box className={classes.main}>
      <BookmarkDeleteModal 
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={modalTitle}
        handleSuccess={handleRemoveBookmark}
        loading={updating}/>

      <Container maxWidth="lg">
        <Grid container  
            pt={2} 
            height={'calc(100vh - 80px)'}
            sx={{ gap: "10px"}}
            display="flex" 
            flexDirection="row">
            <Grid item className={classes.content} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant='logoSub'>Bookmarks</Typography>  
              {
                loading
                ?
                <Box sx={{ width: "100%", height: "100%"}} display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
                :
                <Box> 
                  {
                    bookmarksList.length === 0
                    ?
                    <Box display="flex" justifyContent="center" alignItems="center" p={2} sx={{fontWeight: 500, height: "400px"}}>
                      No Bookmarks Found
                    </Box>
                    :
                    <>
                    <Divider />
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" sx={{ color: "gray", padding: 1, paddingLeft: 2 }}>Article</TableCell>
                          <TableCell align="left" sx={{ color: "gray", padding: 1, paddingLeft: 2 }}>Bookmarked on</TableCell>
                          <TableCell align="left" sx={{ color: "gray", padding: 1, width: "110px" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bookmarksList.map((row, index) => (
                          <TableRow
                            key={row.name}
                          >
                            <TableCell align="left" sx={{ padding: 0.5, paddingLeft: 2, paddingRight: 2, fontWeight: 500 }}>
                              {row.title}
                            </TableCell>
                            <TableCell align="left" sx={{ padding: 0.5, paddingLeft: 2 }}>
                              {new Date(row.createdAt).toDateString().slice(4).substring(0, 6) + ", "+ new Date(row.createdAt).toDateString().slice(4).substring(6, 11)|| ""}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: 0.5 }}>
                              <IconButton
                                onClick={() => {
                                  setDeleteId(row._id);
                                  setModalTitle("Removing"); 
                                  setDeleteModalOpen(true);
                                }}>
                                <DeleteIcon color='error'/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            // sx={{ borderBottom: 0}}
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={7}
                            count={bookmarksTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'rows per page',
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                    </>
                  }
                </Box>
              }
            </Grid>
          </Grid>
      </Container>
    </Box>
  )
}

export default BookmarkPage;