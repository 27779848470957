import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const Auth = (token, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.auth(token);
    console.log(data)
    dispatch({ type: actionTypes.AUTH, payload: data });
    setLoading(false);

  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};