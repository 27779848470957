import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from "react-toastify";

export const GetArticles = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.getArticles();
    dispatch({ type: actionTypes.GET_ARTICLES, payload: data });
    setLoading(false);

  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
    toast.error("Something went wrong")
  }
};

export const GetSpecificArticles = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var res;
  try {
    const { data } = await api.getSpecificArticle(id);
    res = data;
    dispatch({ type: actionTypes.GET_SPEC_ARTICLE, payload: data });
    setLoading(false);

  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
    toast.error("Something went wrong")
  }
  return res;
};

export const CreateArticle = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  var res;
  try {
    const { data } = await api.createArticle(formData);
    console.log(data)
    res = data;
    dispatch({ type: actionTypes.CREATE_ARTICLE, payload: data });
    setLoading(false);
    toast.success("Article created successfully!")
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
  return res;
};

export const MoreByAuthor = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.moreByAuthor(id);
    console.log(data)
    dispatch({ type: actionTypes.MORE_BY_AUTHOR, payload: data });
    setLoading(false);

  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};
  