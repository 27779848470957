import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const GetComments = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.getComments(id);
    dispatch({ type: actionTypes.GET_COMMENTS, payload: data });
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const PostComments = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.postComments(formData);
    console.log(data);
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const LikeComment = (id, type, replyId) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.likeComment(id, type, replyId);
    console.log(data);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
};