import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      backgroundColor: "#f3f3f3",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
    },
    minHeight: "calc(100vh - 60px)",
  },
  articleCard: {
    margin: "0px 0px 10px 0px",
    padding: "15px",
    backgroundColor: "#ffffff", 
    borderRadius: "12px",
    cursor: "pointer"
  }

}));

export default useStyles;