import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navbar: {
    padding: "10px 0",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 10px 0px;",
    position: "sticky",
    zIndex: 2,
    top: "0px",
    backgroundColor: "#fff"
  },

  create__btn: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 10,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 36,
    padding: '0 20px',
    fontSize: 14
  }

});

export default useStyles;