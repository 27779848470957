import axios from "axios";
import { toast } from "react-toastify";

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request Interceptor
API.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.token}`;
  }
  return req;
});

// Response Interceptor
API.interceptors.response.use(
  (response) => {
    // console.log(response);
    return response;
  },
  (error) => {
    // 5XX error 
    if((error.status+"")[0] === "5") toast.error("Internal server error")

    return Promise.reject(error);
  }
);

export const auth = (token) => API.post("auth", token);

// articles
export const getArticles = () => API.get("article");
export const getSpecificArticle = (id) => API.get(`article/${id}`);
export const createArticle = (form) => API.post("article", form );
export const moreByAuthor = (id) => API.get(`article/moreByAuthor/${id}`);

// comments
export const getComments = (id) => API.get(`comment/${id}`);
export const postComments = (form) => API.post(`comment`, form);
export const likeComment = (id, type, replyId) => API.patch(`comment/like/${id}?Type=${type}&ReplyId=${replyId}`);


// dashboard
export const getMyArticles = () => API.get('article/dashboard/getMyArticles');
export const getMySpecificArticle = (id) => API.get(`article/dashboard/getMySpecificArticle/${id}`);
export const updateMyArticle = (id, form) => API.put(`article/dashboard/updateMyArticle/${id}`, form);
export const updateArticleStatus = (id, status) => API.put(`article/dashboard/updateArticleStatus/${id}?status=${status}`);
export const deleteMyArticle = (id) => API.delete(`article/dashboard/deleteMyArticle/?id=${id}`);


// bookmarks
export const getMyBookmarks = () => API.get('bookmark/getMyBookmarks');
export const checkBookmark = (id) => API.get(`bookmark/checkBookmark/${id}`);
export const addBookmark = (id) => API.post(`bookmark/addBookmark/${id}`);
export const deleteBookmark = (id) => API.delete(`bookmark/deleteBookmark/${id}`);