import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./protectedRoutes";
import PublicRoutes from "./publicRoutes";

import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/Footer";

//routes
import AuthPage from "../views/authPage/AuthPage";
import HomePage from "../views/homePage/HomePage";
import CreatePage from "../views/createPage/CreatePage";
import ReadPage from "../views/readPage/ReadPage";
import ProfilePage from "../views/profilePage/ProfilePage";
import DashboardPage from "../views/dashboardPage/DashboardPage";
import BookmarkPage from "../views/bookmarkPage/BookmarkPage";
import SettingsPage from "../views/settingsPage/SettingsPage";

const MainRoutes = () => (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<ReadPage />} />

        {/** Protected Routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/edit/:id" element={<CreatePage />} />
          {/* <Route path="/read" element={<ReadPage />} /> */}
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/bookmark" element={<BookmarkPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Route>

        {/** Public Routes */}
        <Route path="/" element={<PublicRoutes />}>
          {/* <Route path="/" exact element={<HomePage />} />
          <Route path="/:id" element={<ReadPage />} /> */}
          <Route path="/auth" element={<AuthPage />} />
        </Route>
      </Routes>
      <Footer /> 
    </Router>
  );


  // RESERVED WORDS - PROFILE AND DASHBOARD
  
  export default MainRoutes;
  