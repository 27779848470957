import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from "react-toastify";

export const GetMyBookmarks = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.getMyBookmarks();
    dispatch({ type: actionTypes.GET_MY_BOOKMARKS, payload: data });
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const CheckBookmark = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.checkBookmark(id);
    dispatch({ type: actionTypes.CHECK_BOOKMARK, payload: data.exists });
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
};

export const AddBookmark = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.addBookmark(id);
    toast.success("Booked successfully!")
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
};

export const DeleteBookmark = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.deleteBookmark(id);
    toast.success("Booked deleted successfully!")
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
};