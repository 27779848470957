import Router from './routes/routes';

import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import CssBaseline from '@mui/material/CssBaseline';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
            {/* <CssBaseline /> */}
          <Router />
          <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
