import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  CircularProgress,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  Divider,
  Popover,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

// import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import _ from 'lodash';

import { DeleteMyArticle, GetMyArticles, UpdateArticleStatus } from '../../../redux/actions/dashboardActions';

import ArchiveModal from '../Modals/ArchiveModal';
import DeleteModal from '../Modals/DeleteModal';

// import useStyles from '../styles';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ContentPanel = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.dashboard);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [selected, setSelected] = useState();

  const [articlesList, setArticlesList] = useState([]);
  const [articlesTotal, setArticlesTotal] = useState(0);

  const [updateObj, setUpdateObj] = useState({});
  const [statusChange, setStatusChange] = useState("");

  // alertModals
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [anchorStatus, setAnchorStatus] = useState(null);
  const open = Boolean(anchorStatus);
  const id = open ? 'simple-popover' : undefined;

  // ---------------- table pagination ------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };   


  useEffect(() => {
    dispatch(GetMyArticles(setLoading))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    try {
      console.log(state);
      formatArticles(state.myArticles || []);
      setArticlesTotal(state.myArticles.length || 0);
    } catch (err) {}
  }, [state]);

  // ------------------ formatters --------------------
  const formatArticles = (list) => {
    setArticlesList(list);
    setSelected([ ...Array(list.length).fill(false)])
  }

  // ------------------ submit handlers ---------------
  const handleStatusChange = () => {
    dispatch(UpdateArticleStatus(updateObj._id, statusChange, setUpdating)).then(() => {
      dispatch(GetMyArticles(setUpdating)).then(() => {
        setAnchorStatus(null);
      })
    });
  }

  const handleArchiveArticle = () => {

  }

  const handleDeleteArticle = () => {
    var ids = [];
    for(let i = 0; i < articlesList?.length; i++){
      if(selected[i] === true) ids.push(articlesList[i]._id);
    }
    console.log(ids);
    ids = ids.join(",");
    console.log(ids);
    dispatch(DeleteMyArticle(ids, setUpdating)).then(() => {
      dispatch(GetMyArticles(setUpdating)).then(() => {
        setDeleteModalOpen(false);
      })
    });
  }

  // ----------------- miscelleanous -----------------
  const getSelectedCount = () => {
    var count = 0;
    for(let i = 0 ; i < selected?.length; i++){
      if(selected[i] === true) count++;
    }
    return count;
  }

  return (
    <Box>
      <ArchiveModal 
        open={archiveModalOpen}
        setOpen={setArchiveModalOpen}
        title={modalTitle}
        count={getSelectedCount()}
        handleSuccess={handleArchiveArticle}
        loading={updating}/>

      <DeleteModal 
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={modalTitle}
        count={getSelectedCount()}
        handleSuccess={handleDeleteArticle}
        loading={updating}/>

      {
        loading
        ?
        <Box sx={{ width: "100%", height: "400px"}} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
        :
        <Box>
          <Box display="flex" flexDirection="row" gap={2} pt={1} pb={1}>
            {/* <Button 
              variant="contained" 
              color="warning" 
              sx={{ color: "#fff"}} 
              disabled={(getSelectedCount() === 0 ? true : false)} 
              onClick={() => {setModalTitle("Archiving"); setArchiveModalOpen(true);}}
              startIcon={<ArchiveOutlinedIcon />}>
                Archive
            </Button>   */}
            <Button 
              variant="contained" 
              color="error" 
              sx={{ color: "#fff"}} 
              disabled={(getSelectedCount() === 0 ? true : false)} 
              onClick={() => {setModalTitle("Deleting"); setDeleteModalOpen(true);}}
              startIcon={<DeleteOutlineOutlinedIcon />}>
                Delete
            </Button>
          </Box>    
          <Divider />
          {
            articlesList.length === 0
            ?
            <Box display="flex" justifyContent="center" alignItems="center" p={2} sx={{fontWeight: 500}}>
              No Articles Found
            </Box>
            :
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: 0.5, width: "38px"}}>
                    <Checkbox 
                      checked={selected?.includes(false) ? false : true}
                      onChange={(e) => {
                        var temp = _.cloneDeep(selected);
                        temp = temp.map(i => i = e.target.checked);
                        setSelected(temp);
                      }}/>
                  </TableCell>
                  <TableCell align="left" sx={{ color: "gray", padding: 0.5 }}>Article</TableCell>
                  <TableCell align="left" sx={{ color: "gray", padding: 0.5, width: "120px" }}>Status</TableCell>
                  <TableCell align="left" sx={{ color: "gray", padding: 0.5, width: "120px" }}>Date</TableCell>
                  <TableCell align="right" sx={{ color: "gray", padding: 0.5, width: "120px" }}>Views</TableCell>
                  <TableCell align="right" sx={{ color: "gray", padding: 0.5, width: "150px" }}>Comments</TableCell>
                  <TableCell align="right" sx={{ color: "gray", padding: 0.5, width: "110px" }}>Likes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articlesList.map((row, index) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" sx={{ padding: 0.5, width: "42px"}}>
                      <Checkbox 
                        checked={selected[index]}
                        onChange={(e) => {
                          var temp = _.cloneDeep(selected);
                          temp[index] = e.target.checked;
                          setSelected(temp);
                        }}
                        />
                    </TableCell>
                    <Tooltip title="Click to Update Article">
                      <TableCell align="left" sx={{ padding: 0.5, paddingRight: 2, fontWeight: 500, cursor: "pointer" }}
                        onClick={() => { navigate(`/edit/${row.slug}`)}}>
                        {row.title}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="left" sx={{ padding: 0.5, cursor: "pointer" }} onClick={(e) => {setAnchorStatus(e.currentTarget); setUpdateObj(row); setStatusChange(row.status);}}>
                      <u>{row.status}</u>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: 0.5 }}>
                      {new Date(row.createdAt).toDateString().slice(4).substring(0, 6) + ", "+ new Date(row.createdAt).toDateString().slice(4).substring(6, 11)|| ""}
                    </TableCell>
                    <TableCell align="right" sx={{ padding: 0.5 }}>{row.fat}</TableCell>
                    <TableCell align="right" sx={{ padding: 0.5 }}>{row.carbs}</TableCell>
                    <TableCell align="right" sx={{ padding: 0.5 }}>{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    // sx={{ borderBottom: 0}}
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={7}
                    count={articlesTotal}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          
        </Box>
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorStatus}
        onClose={() => setAnchorStatus(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { borderRadius: 12 }}}
      >
        <Box sx={{ width: "300px"}}>
          <Typography sx={{ p: 2, pb: 1, fontWeight: 600 }}>Change Status</Typography>
          <FormControl sx={{ pl: 2, pr: 2, pb: 1 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={statusChange}
              onChange={(e) => setStatusChange(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel value="Published" control={<Radio />} label="Published" />
              <FormControlLabel value="Unpublished" control={<Radio />} label="Unpublished" />
            </RadioGroup>
          </FormControl>  
          <Divider />
          <Box sx={{ p: 2 }} display="flex" justifyContent="flex-end" gap={"10px"}>
            <Button onClick={() => setAnchorStatus(null)}>cancel</Button>
            <LoadingButton variant="contained" sx={{ color: "#fff"}} onClick={handleStatusChange} loading={updating}>Save</LoadingButton>
          </Box>
        </Box>
          
      </Popover>
    </Box>
  )
}

export default ContentPanel;