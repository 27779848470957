import React from 'react';
import { Box, Container, Typography, Divider, Grid } from '@mui/material';

import useStyles from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Container maxWidth="lg">
        <Box>
          <Grid container mb={2} pt={6} pb={6} display="flex" flexDirection="row" justifyContent="space-between"> 
            <Grid item  xs={12} sm={12} md lg={6} xl={6} mb={2}>
              <Typography variant='h3'>FoodishCORE</Typography>
              <Typography variant='body1' sx={{ color: "#fff" }}>for Food Technology Community</Typography>
            </Grid>
            <Grid item  xs={12} sm={12} md="auto" lg="auto" xl="auto" >
              <Typography variant='h5'>Contact us</Typography>
              <Typography variant='body1' sx={{ color: "#fff" }}>
                <a href="mailto:vinaysingh030999@gmail.com" 
                  style={{ 
                      textDecoration: "none", 
                      color: "white"}}>
                  vinaysingh030999@gmail.com
                </a>
              </Typography>
              <Typography variant='body1' sx={{ color: "#fff" }}>
                <a href="mailto:nitin99bop2cod2f@gmail.com" 
                  style={{ 
                      textDecoration: "none", 
                      color: "white"}}>
                  nitin99bop2cod2f@gmail.com
                </a>
              </Typography>
            </Grid>
            
          </Grid>
          <Divider sx={{ backgroundColor: "#aaa" }}/>
          <Box textAlign="center" mt={2}>
            <Typography variant='subtitle2'>© 2022 - 2023 foodishCORE Community&nbsp;({process.env.REACT_APP_VERSION})</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer;