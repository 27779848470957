import * as actionTypes from "../actionTypes.js";

const initialState = {
  myBookmarks: null,
  checkBookmark: false
};

const bookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_BOOKMARKS:
      return { ...state, myBookmarks: action.payload };
    case actionTypes.CHECK_BOOKMARK:
      return { ...state, checkBookmark: action.payload };
    default:
      return state;
  }
};

export default bookmarkReducer;
