import React from 'react';
import { Box } from '@mui/material';

const AnalyticsPanel = () => {
  return (
    <Box>
      Analytics
    </Box>
  )
}

export default AnalyticsPanel;