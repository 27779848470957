/* global google */
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { 
  Box, 
  Container, 
  Typography, 
  Avatar, 
  Button, 
  Tooltip, 
  IconButton, 
  Menu, 
  MenuItem, 
  Divider 
} from '@mui/material';

import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PersonIcon from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { Auth } from '../../redux/actions/authActions';

import useStyles from "./styles";

const NavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).result : null;
  console.log(location);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['g_state']);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    sessionStorage.clear();
    if(location.pathname === "/profile" || 
      location.pathname === "/dashboard" || 
      location.pathname === "/bookmark" ||
      location.pathname === "/settings" ||
      location.pathname.includes("/edit")){
        navigate("/auth");
      }
  }

  const handleResponse = (res) => {
    dispatch(Auth(res.credential, setLoading))
      // .then(() => navigate("/"))
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleResponse
      })
      user === null && googlePrompt();
    }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googlePrompt  = () => {
    removeCookie('g_state');
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    )
    // If we have no user: sign in button
    // If we have a user : show the log out button
    google.accounts.id.prompt();
  } 

  return (
    <Box className={classes.navbar}>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant='logo' color="secondary" onClick={() => navigate("/")} sx={{ cursor: "pointer"}}>
            FoodishCORE
          </Typography>
          {
            user === null
            ?
            <Button className={classes.create__btn} onClick={googlePrompt}>
              Login
            </Button>
            :
            <Box display="flex" flexDirection="row" alignItems="center" gap="15px">
              {
                location.pathname !== "/create" &&
                <Button className={classes.create__btn} onClick={() => navigate("/create")}>
                  Write
                </Button>
              }
              <Tooltip title="Account settings">
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  sx={{ p: 0 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                <Avatar alt={user.name} src={user.displayPic}  size={50}/>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => navigate("/profile")}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => navigate("/dashboard")}>
                  <ListItemIcon>
                    <DashboardIcon fontSize="small"/> 
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={() => navigate("/bookmark")}>
                  <ListItemIcon>
                    <BookmarkIcon fontSize="small" />
                  </ListItemIcon>
                  Bookmarks
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/settings")}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>

            </Box>
          }
        </Box>
      </Container>
    </Box>
  )
}

export default NavBar;