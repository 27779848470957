import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: theme.palette.darkGray.main,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.darkGray.main,
    },
    color: theme.palette.primary.main,
    padding: "40px 0px 10px 0px"
  },

}));

export default useStyles;