import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Grid, Divider, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';


import useStyles from './styles';

const ProfilePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.main}>
      <Container maxWidth="lg">
        <Grid container  
            pt={2} 
            sx={{ gap: "10px"}}
            display="flex" 
            flexDirection="row">
            <Grid item 
              className={classes.profile__topPanel} xs={12} sm={12} md={12} lg={12} xl={12}>
              <img 
                src='https://cdn.pixabay.com/photo/2021/04/16/05/55/leaves-6182624_960_720.jpg' 
                alt='' 
                style={{ width: "100%", height: "200px", borderRadius: "12px 12px 0px 0px", objectFit: "cover" }}/>
              {/* <Divider /> */}
              <Box display={"flex"} flexDirection="row" sx={{ margin: "auto" }} maxWidth="md" >
                <Avatar sx={{ width: "86px", height: "86px", marginRight: "16px" }} /> 
                <Box sx={{ width: "100%"}}>
                  <Typography variant='h4'>Nitron</Typography>
                  <Typography variant='body1'>@nitrron99</Typography>
                  <Box display="flex" justifyContent="end">
                    <Typography variant='body1' className={classes.bottomLinks} onClick={() => navigate("/dashboard")}>Dashboard</Typography>
                    <Typography variant='body1' className={classes.bottomLinks} onClick={() => navigate("/settings")}>Settings</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item 
              className={classes.content} xs={12} sm={12} md={12} lg={12} xl={12}>


    

            </Grid>
          </Grid>
      </Container>
    </Box>
  )
}

export default ProfilePage;