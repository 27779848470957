import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { CookiesProvider } from 'react-cookie';


import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <CssVarsProvider>
           <CssBaseline /> */}
           <CookiesProvider >
              <App />
            </CookiesProvider >
      {/* </CssVarsProvider> */}
    </PersistGate>
  </Provider>
);