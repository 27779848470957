import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Tab, Tabs  } from '@mui/material';

import useStyles from './styles';
import PropTypes from 'prop-types';

// Panels
import AnalyticsPanel from './Panels/AnalyticsPanel';
import ArchivesPanel from './Panels/ArchivesPanel';
import ContentPanel from './Panels/ContentPanel';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0, pb:2, height: "calc(100vh - 230px)"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const DashboardPage = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.main}>
      <Container maxWidth="lg">
        <Grid container  
            pt={2} 
            sx={{ gap: "10px" }}
            display="flex" 
            flexDirection="row">
            <Grid item
              className={classes.content} xs={12} sm={12} md={12} lg={12} xl={12}>
                
              <Typography variant='logoSub'>Dashboard</Typography>

              <Box sx={{ width: '100%', marginTop: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Content" {...a11yProps(0)} sx={{ fontWeight: 600 }}/>
                    <Tab label="Analytics" {...a11yProps(1)} sx={{ fontWeight: 600 }}/>
                    {/* <Tab label="Archives" {...a11yProps(2)} sx={{ fontWeight: 600 }}/> */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <ContentPanel />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AnalyticsPanel />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                  <ArchivesPanel />
                </TabPanel> */}
              </Box>

            </Grid>
          </Grid>
      </Container>
    </Box>
  )
}

export default DashboardPage;