import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Chip, 
  Container, 
  Divider, 
  Tooltip, 
  Typography, 
  Skeleton 
} from '@mui/material';

import { GetArticles } from '../../redux/actions/articlesActions';

import useStyles from './styles';

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const articles = useSelector(state => state.articles);

  const [articlesList, setArticlesList] = useState([]);

  useEffect(() => {
    dispatch(GetArticles(setLoading))
    console.log("calling")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(articles) console.log(articles)
    try {
      setArticlesList(articles.articles || []);
    } catch (err) {
      console.log(err);
    }
  },[articles])

  return (
    <Box className={classes.main} pt={2} pb={2}>
      <Container maxWidth="lg">
        {
          loading
          ?
          <>
            <Skeleton variant="rectangular" height={100} sx={{ borderRadius: "12px", marginBottom: 2 }} />
            <Skeleton variant="rectangular" height={100} sx={{ borderRadius: "12px", marginBottom: 2 }} />
            <Skeleton variant="rectangular" height={100} sx={{ borderRadius: "12px", marginBottom: 2 }} />
          </>
          :
          articlesList.map((item, index) => (
            <Box className={classes.articleCard} key={index} onClick={() => navigate(`/${item.slug}`)}>
              <Typography variant='logoSub' pb={2}>{item.title}</Typography>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>
              {
                item.tags.length === 0
                ?
                'No Tags Found'
                :
                item.tags.map((tag, tagId) => (
                <Tooltip title={tag}>
                  <Chip label={"#"+tag} sx={{ marginRight: 1 }}/>
                </Tooltip>
              ))}
            </Box>
          ))
        }
      </Container>
    </Box>
  )
}

export default HomePage;