//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const AUTH = "AUTH";
export const TOKEN_FETCH = 'TOKEN_FETCH';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOG_OUT = "LOG_OUT";

//ARTICLE
export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_SPEC_ARTICLE = 'GET_SPEC_ARTICLE';
export const CLEAR_SPEC_ARTICLE = 'CLEAR_SPEC_ARTICLE';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const MORE_BY_AUTHOR = 'MORE_BY_AUTHOR';

//COMMENT
export const GET_COMMENTS = 'GET_COMMENTS';


//DASHBOARD
export const GET_MY_ARTICLES = 'GET_MY_ARTICLES';
export const GET_MY_SPEC_ARTICLES = 'GET_MY_SPEC_ARTICLES';


//BOOKMARK
export const GET_MY_BOOKMARKS = 'GET_MY_BOOKMARKS';
export const CHECK_BOOKMARK = 'CHECK_BOOKMARK';