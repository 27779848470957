/* global google */
import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { 
  Box, 
  Container, 
  Typography, 
  Button 
} from '@mui/material';

import { Auth } from '../../redux/actions/authActions';

// import jwt_decode from 'jwt-decode';

import useStyles from "./styles";

// import Button from '@mui/joy/Button';
// import { CssVarsProvider } from '@mui/joy/styles';

const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['g_state']);
  const userAuth = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).result : null;

  const handleResponse = (res) => {
    dispatch(Auth(res.credential, setLoading))
      .then(() => navigate("/"))

    // var userObject = jwt_decode(res.credential)
    // console.log(userObject)
    // setUser(userObject)
    // document.getElementById("signInDiv").hidden = true
    // sessionStorage.setItem("user", JSON.stringify(userObject));
    // navigate("/Home")
  }

  const handleFailure = (err) => {
    console.log(err)
  }

  const handleSignOut = (e) => {
    setUser({})
    document.getElementById("signInDiv").hidden = false
  }


  useLayoutEffect(() => {
  
    setTimeout(() => {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleResponse
      })
      userAuth === null && googlePrompt();
    }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googlePrompt  = () => {
    removeCookie('g_state');
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    )
    // If we have no user: sign in button
    // If we have a user : show the log out button
    google.accounts.id.prompt();
  } 
  
  return (
    <Box>
      <Container maxWidth="lg">
        <Box 
          height={"600px"} 
          display="flex"
          alignItems="center"
          flexDirection="column"
          pt={"100px"}
          > 
          <Typography fontSize={"92px"} fontWeight={"600"} lineHeight={1}>
            Build for Food <br/> Technologists
          </Typography>
          <Typography fontSize={"33px"} fontWeight={"400"} lineHeight={3}>
            One Stop Solution for <span style={{ color: "#19857b"}}>Food Technology</span>
          </Typography>

          <Button className={classes.signup__btn} onClick={googlePrompt}>
            Sign up
          </Button>
        </Box>


      </Container>

      {/* <div id='signInDiv'></div> */}
      {/* {
        Object.keys(user).length !== 0 &&
        (
          <>
            <button onClick={(e) => handleSignOut(e)} >Log out</button>
          
          </>
        )
      } */}
      {/* {user &&
        <div>
          <img src={user.picture} alt="" />
          <h3>{user.name}</h3>
        </div>
      } */}
    </Box>
  )
}

export default AuthPage;