import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const BookmarkDeleteModal = ({open, setOpen, title, handleSuccess, loading}) => {

  useEffect(() => {

  }, [open]);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 12 }}}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this bookmark?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box p={1}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton onClick={handleSuccess} variant="contained" sx={{ color: "#fff"}} color="error" loading={loading}>
            Remove
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default BookmarkDeleteModal;