import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      backgroundColor: "#f3f3f3",
    },
    minHeight: "calc(100vh - 60px)"
  },

  content: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      padding: "24px",
      borderRadius: "12px",
      // border: "solid 1px #d4d4d4",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
    },
  },

}));

export default useStyles;