import * as actionTypes from "../actionTypes.js";

const initialState = {
  myArticles: null,
  mySpecificArticle: null
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_ARTICLES:
      return { ...state, myArticles: action.payload };
    case actionTypes.GET_MY_SPEC_ARTICLES:
      return { ...state, mySpecificArticle: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
