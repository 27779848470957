import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#4ca885",
      light: "#4ca885"
    },
    secondary: {
      main: '#19857b',
    },
    Text: {
      white: "#fff",
      main:'#19857b',
    },
    darkGray: {
      main: "rgba(4, 4, 4, 0.8)"
    },
    black: {
      main: "#000000"
    },
    white: {
      main: "#ffffff"
    },
    error: {
      main: "#f73619"
    }
  },
  typography: {
    fontFamily: "Poppins",
    // custom typography
    logo: {
      fontSize: 26,
      fontWeight: "600"
    },
    logoSub: {
      fontSize: 20,
      fontWeight: "600"
    },
    heading: {
      fontSize: 42,
      fontWeight: "600",
      lineHeight: 1.1
    },
    // mui typography
    h1: {
      fontSize: "6rem",
      fontWeight: 300,
      lineHeight: 1.167
    },
    h2: {
      fontSize: "3.75rem",
      fontWeight: 300,
      lineHeight: 1.2
    },
    h3: {
      fontSize: "3.75rem",
      fontWeight: 400,
      lineHeight: 1.167
    },
    h4: {
      fontSize: "2.125rem",
      fontWeight: 400,
      lineHeight: 1.235
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: 1.334
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.6
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: "0.875",
      fontWeight: 500,
      lineHeight: 1.57
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.43
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75
    }
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          fontSize: "15px",
          padding: "12px 15px",
          "&.Mui-focused": {
            "borderColor": "Red"
          }
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "3px",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px", 
          minWidth: "90px"
        },
      }
    }
  },



  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  
});

export default theme;