import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const useAuth = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const location = useLocation();
  const auth = useAuth();

  return auth ? (
    <>
      {<Outlet/ >}
    </>
  ) :
  location.pathname === "/auth" 
  // <> </> 
  //   //   ?  
  //   //     <Outlet /> 
  //   //   : 
  //   //   <Navigate to="/" />
  // ;
};

export default ProtectedRoutes;
