import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from "react-toastify";

export const GetMyArticles = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.getMyArticles();
    dispatch({ type: actionTypes.GET_MY_ARTICLES, payload: data });
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
    toast.error("Something went wrong");
  }
};

export const GetMySpecificArticle = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.getMySpecificArticle(formData);
    dispatch({ type: actionTypes.GET_MY_SPEC_ARTICLES, payload: data });
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
    toast.error("Something went wrong");
  }
};

export const UpdateMyArticle = (id, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.updateMyArticle(id, formData);
    toast.success("Article updated successfully!");
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};


export const UpdateArticleStatus = (id, status, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.updateArticleStatus(id, status);
    toast.success("Status changed successfully!");
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const DeleteMyArticle = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.deleteMyArticle(id);
 
    toast.success("Article deleted successfully!");
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};
