import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      backgroundColor: "#f3f3f3",
    },
  },
  sideBar:{
    // padding: 15,
    height: "600px",
    width: "300px",
    minWidth: "290px",
    [theme.breakpoints.up('md')]: {
      maxWidth: "320px",
    },
    marginLeft: "auto",
    position: "sticky",
    top: "76px",
    // backgroundColor: "red",
  },

  sideBarBox:{
    padding: 15,
    marginBottom: "16px",
    // width: "300px",
    // minWidth: "290px",
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: "320px",
    // },
    // backgroundColor: "red",
    borderRadius: "12px",
    border: "solid 1px #d4d4d4",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;"
  },


  content: {
    backgroundColor: "#ffffff",
    [theme.breakpoints.up('md')]: {
      padding: "24px",
      borderRadius: "12px",
      // border: "solid 1px #d4d4d4",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
    },
  },

  comment: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  commentText: {
    padding: "10px 15px",
    width: "100%",
    borderRadius: "12px",
    border: "solid 1px #d4d4d4",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;"
  },
  commentLike: {
    padding: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px"
  },
  commentReply:{
    "&:hover": {
      textDecoration: "underline"
    }
  }

}));

export default useStyles;