import * as actionTypes from "../actionTypes.js";

const initialState = {
  articles: null,
  specificArticle: null,
  createArticle: null,
  moreByAuthor: null
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ARTICLES:
      return { ...state, articles: action.payload };
    case actionTypes.GET_SPEC_ARTICLE:
      return { ...state, specificArticle: action.payload };
    case actionTypes.CLEAR_SPEC_ARTICLE:
      return { ...state, specificArticle: null };
    case actionTypes.CREATE_ARTICLE:
      return { ...state, createArticle: action.payload };
    case actionTypes.MORE_BY_AUTHOR:
      return { ...state, moreByAuthor: action.payload };

    default:
      return state;
  }
};

export default articleReducer;
