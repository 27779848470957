import React, { useState, useEffect, useRef } from 'react';
import EditorJS  from '@editorjs/editorjs';
import Header from  '@editorjs/header';
import List from  '@editorjs/list';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';

import edjsParser from "editorjs-html";

import "./styles.css";
import { Typography, Button, Divider, Box } from '@mui/material';
import useStyles from '../navBar/styles';

const Editor = ({ readMode, readData, returnData, loading}) => {
  const editorRef = useRef();
  const classes = useStyles();
  const parser = new edjsParser();
  const [wordCount, setWordCount] = useState(0);
  const [EditorInstance, setEditorInstance] = useState("");

    // console.log(parser)

    
  useEffect(() => {
    // if(editorRef.current !== null & editorRef.current !== undefined){
      setEditorInstance(new EditorJS({
        holder: 'editorjs', 
        // logLevel: "ERROR",
        data: readData,
        readOnly: readMode,
        onReady: () => {
          console.log('Editor.js is ready to work!')
        },
        tools: {
          header: {
            class: Header,
            inlineToolbar : true,
            config: {
              placeholder: 'Enter a header',
              levels: [2, 3, 4],
              defaultLevel: 3,
              defaultAlignment: 'left'
            }
          },
          list: {
            class: List,
            inlineToolbar : true
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: 'Quote\'s author',
            },
          },
          image: SimpleImage,
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              titlePlaceholder: 'Title',
              messagePlaceholder: 'Message',
            },
          },
          delimiter: Delimiter,
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
        },
      }))
    // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readData]);

  useEffect(() => {
    if(editorRef.current !== null & editorRef.current !== undefined){
      console.log(editorRef)
      var childs = editorRef.current.childNodes
      if(childs !== undefined) {
        let i = 0;
        while(i < childs.length-1){
          editorRef.current.firstElementChild.remove();
          i++;
        }
      }
    }
  }, [EditorInstance]);


  useEffect(() => {
    setTimeout(() => {
      if(editorRef.current !== null & editorRef.current !== undefined){
        if(editorRef.current.childNodes.length > 1){
          editorRef.current.firstElementChild.remove();
        }
      }
    }, 500);
  }, [editorRef]);


   // useEffect(() => {
  //   if (!ejInstance.current) {
  //     initEditor();
  //   }
  //   return () => {
  //     ejInstance.current.destroy();
  //     ejInstance.current = null;
  //   }
  // }, []);

  const save = () => {
    EditorInstance.save()
      .then((outputData) => returnData(outputData))
      .catch((error) => console.log('Saving failed: ', error));
  }

  return (
    <div>
      {      
        readMode
        ?
        <div ref={editorRef} id="editorjs"/>
        :
        <>
          {!loading && <Typography fontSize={30} fontWeight={600} color="#bdbdbd"> <i>-----CONTENT-----</i> </Typography>}
          { 
            !loading && 
            <>
            <Box sx={{ borderRadius: "12px", padding: "20px 20px 20px 40px", border: "solid 1px #d4d4d4" }}>
              <div ref={editorRef} id="editorjs"/>
            </Box>
                <Divider variant="middle"/>
                <Box display="flex" flexDirection="row" mt={3} gap={"15px"} mb={3}>
                  <Button onClick={save} className={classes.create__btn}>Publish</Button>
                  <Button variant='outlined'>Draft</Button>
                </Box>
            </>
          }
        </>
      }
    </div>
  )
}

export default Editor;