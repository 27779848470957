import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Editor from '../../components/editor/Editor';
import { 
  Box, 
  Container, 
  Typography, 
  Divider, 
  Tooltip, 
  Grid, 
  TextField, 
  Avatar, 
  IconButton, 
  CircularProgress, 
  Button, 
  Skeleton
} from '@mui/material';
import moment from "moment";
import _ from "lodash"; 

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import SendIcon from '@mui/icons-material/Send';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import useStyles from './styles';
import { GetComments, PostComments, LikeComment } from '../../redux/actions/commentsActions';
import { GetSpecificArticles, MoreByAuthor} from '../../redux/actions/articlesActions';
import { useNavigate, useParams } from 'react-router-dom';

import * as actionTypes from "../../redux/actionTypes";
import { AddBookmark, CheckBookmark, DeleteBookmark } from '../../redux/actions/bookmarkActions';

const ReadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loadingCmts, setLoadingCmts] = useState(true);
  const params = useParams();
  const [commenting, setCommenting] = useState(false);
  const articles = useSelector(state => state.articles);   
  const state = useSelector(state => state.comments);
  const bookmark = useSelector(state => state.bookmark);
  const [bookmarked, setBookmarked] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [articleObj, setArticleObj] = useState({});
  const [moreArticle, setMoreArticle] = useState([]);
  // ---------- reply comment --------------------------
  const [openReplyCmt, setOpenReplyCmt] = useState(false);
  const [replyComment, setReplyComment] = useState("");
  const [replyCommentId, setReplyCommentId] = useState(null);

  const [replyToReplyCommentId, setReplyToReplyCommentId] = useState(null);

  const userId = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).result._id : null;
  const user = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).result : null;
  

  useEffect(() => {
    setArticleObj({});
    try {
      console.log(params.id)
      if(params.id !== "dashboard"){
        dispatch(GetSpecificArticles(params.id, setLoading)).then((res) => {
          dispatch(MoreByAuthor(res.author._id, setLoadingMore));
          dispatch(CheckBookmark(res._id));
        });
      }
    } catch (error) {
      console.log(error)
    }

    return(() => {
      dispatch({ type: actionTypes.CLEAR_SPEC_ARTICLE });
      setArticleObj({});
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[navigate]);

  useEffect(() => {
    try {
      // console.log(comments)
      setComments(state.comments || []);
    } catch (error) {
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(() => {
    try {
      // console.log(article);
      // if(!loading) {
        setArticleObj(articles.specificArticle);
        setMoreArticle(articles.moreByAuthor || []);
        // for calling comments for current article
        if(articles.specificArticle) dispatch(GetComments(articles?.specificArticle?._id, setLoadingCmts))
      // }
    } catch (error) {
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[articles]);

  useEffect(() => {
    try {
      console.log(bookmark)
      setBookmarked(bookmark.checkBookmark);
    } catch (error) {
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bookmark]);

  // --------------------- new comment post --------------------
  const submitComment = () => {
    setNewComment("");
    try {
      var obj = { 
        text: newComment,
        parentId: articles?.specificArticle?._id,
        articleId: articles?.specificArticle?._id,
        level: 0,
        replyUserId: null
      }
      if(articles?.specificArticle?._id.length > 0){
        dispatch(PostComments(obj, setCommenting)).then(() => {
          dispatch(GetComments(articles?.specificArticle?._id, setCommenting))
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitReply = () => {
    setReplyComment("");
    setReplyCommentId(null);
    console.log(replyComment)
    try {
      var obj = { 
        text: replyComment,
        parentId: replyCommentId,
        articleId: articles?.specificArticle?._id,
        level: 1,
        replyUserId: null
      }
      if(articles?.specificArticle?._id.length > 0){
        dispatch(PostComments(obj, setCommenting)).then(() => {
          dispatch(GetComments(articles?.specificArticle?._id, setCommenting))
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitReplyToReply = (pId, id) => {
    setReplyComment("");
    setReplyToReplyCommentId(null);
    console.log(replyComment)
    try {
      var obj = { 
        text: replyComment,
        parentId: pId,
        articleId: articles?.specificArticle?._id,
        level: 1,
        replyUserId: id
      }
      if(articles?.specificArticle?._id.length > 0){
        dispatch(PostComments(obj, setCommenting)).then(() => {
          dispatch(GetComments(articles?.specificArticle?._id, setCommenting))
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // ---------------------- like button ------------------------
  const likeHandler = (id, type, replyId) => {
    console.log(id)
    if( type === "Comment"){
      try {
        dispatch(LikeComment(id, type, "")).then(() => {
          dispatch(GetComments(articles?.specificArticle?._id, setLoading))
        })
      } catch (error) { console.log(error) }
    } else if( type === "Reply"){
      try {
        dispatch(LikeComment(id, type, replyId)).then(() => {
          dispatch(GetComments(articles?.specificArticle?._id, setLoading))
        })
      } catch (error) { console.log(error) }
    }
   
  }

  // ----------------------- bookmarks -------------------------
  const handleBookmark = () => {
    console.log(articleObj._id)
    if(bookmark.checkBookmark){
      // do unbookmark
      dispatch(DeleteBookmark(articleObj._id)).then(() => {
        dispatch(CheckBookmark(articleObj._id));
      });
    }else{
      // do bookmark
      dispatch(AddBookmark(articleObj._id)).then(() => {
        dispatch(CheckBookmark(articleObj._id));
      });
    }
  }

  return (
    <Box className={classes.main} pb={2}>
      <Container maxWidth="lg">
        <Grid container  
          pt={2} 
          sx={{ gap: "10px"}}
          display="flex" 
          flexDirection="row">

          {/* article */}
          <Grid item 
            className={classes.content} xs={12} sm={12} md lg={8.4} xl={8.5}>
            {/* title */}
            {
              loading
              ?
              <Box display="flex" justifyContent="center" p={4}>
                <CircularProgress />
              </Box>
              :
              <Typography variant='heading'>
                {articleObj?.title}
              </Typography>
            }
            {/* tags */}
            <Box display="flex" flexDirection="row" mt={1.5} mb={1.5}> 
            {
              articleObj?.tags?.map((item, index) => {
                return(
                  <Tooltip title="tags" key={index}>
                    <Box mr="15px" sx={{cursor: "pointer"}}>
                      <u><i>{"#"}{item}</i></u>
                    </Box>
                  </Tooltip>
                )
              })
            }
            </Box>

            {/* text article */}
            <Editor 
            readMode={true} 
            readData={articleObj?.blocks}
            returnData={null}
            loading={loading} />

            {/* Comments */}
            <Box>
              <Typography variant='logo'> Comments </Typography>
              {
                user === null
                ?
                <Box display={'flex'} justifyContent={'center'} fullWidth mt={2} mb={2}>
                  <Typography variant='logoSub'>Login to post PostComments</Typography>
                </Box>
                :
                <Box display="flex" flexDirection="row" gap={"20px"} mb={2}>
                    <Avatar alt={user.name[0]} src={user.displayPic} />
                    <TextField 
                      data-gramm="false"
                      data-gramm_editor="false"
                      data-enable-grammarly="false"
                      fullWidth
                      multiline
                      size='small'
                      maxRows={4}
                      label="Comments" 
                      placeholder="Have something to comment?"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)} 
                      InputProps={{endAdornment: 
                        commenting
                        ?
                          <CircularProgress color="secondary" size={"25px"} />
                        :
                        <IconButton onClick={submitComment} sx={{marginTop: "auto", padding: "0px", visibility: newComment.length > 0 ? "visible": "hidden"}} color='primary'>
                          <SendIcon />
                        </IconButton>
                      }}/>
                </Box>
              }
              <Typography variant='logoSub'> Top comments </Typography>
              <Divider />
              <Box mt={2} mb={2}>
                {
                  comments.length === 0
                  ?
                  <Box display={'flex'} justifyContent={'center'} fullWidth mt={2} mb={2}>
                    <Typography variant='logoSub'> No comments yet </Typography>
                  </Box>
                  :
                  comments.map((item, index) => (
                    <div key={index}>
                      <Box display="flex" flexDirection="row" gap={"20px"} mb={2}>
                        <Avatar alt={item.commentedBy.name[0]} src={item.commentedBy.displayPic} />
                        <div className={classes.comment}>
                          <div className={classes.commentText}>
                            <div>
                              {item?.commentedBy?.name} • {moment(item.createdAt).startOf('sec').fromNow()}
                            </div>
                            {item.text}
                          </div>

                          <div className={classes.commentLike}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              {
                                item.likes.find(id => id === userId)
                                ?
                                  <FavoriteIcon sx={{ cursor: "pointer"}} onClick={() => likeHandler(item._id, "Comment")} /> 
                                :
                                  <FavoriteBorderIcon sx={{ cursor: "pointer"}} onClick={() => likeHandler(item._id, "Comment")} /> 
                              }
                              <div style={{ width: "20px"}}>{item.likes.length}</div>
                            </div>
                            <div style={{ cursor: "pointer" }} className={classes.commentReply} 
                              onClick={() => {setOpenReplyCmt(true); setReplyCommentId(item._id)}}> 
                              Reply
                            </div>
                          </div>
                          {/* REPLY COMMENT */}
                          {
                            openReplyCmt && replyCommentId === item._id
                            &&
                            <TextField 
                              data-gramm="false"
                              data-gramm_editor="false"
                              data-enable-grammarly="false"
                              fullWidth
                              multiline
                              maxRows={4}
                              size='small'
                              label="Reply" 
                              placeholder="Have something to comment?"
                              value={replyComment}
                              onChange={(e) => setReplyComment(e.target.value)} 
                              InputProps={{endAdornment: 
                                commenting
                                ?
                                  <CircularProgress color="secondary" size={"25px"} />
                                :
                                <IconButton onClick={() => submitReply()} sx={{marginTop: "auto", padding: "0px", visibility: replyComment.length > 0 ? "visible": "hidden"}} color='primary'>
                                  <SendIcon />
                                </IconButton>
                              }}/>
                          }
                        </div>
                      </Box>
                      {/* REPLY COMMENT VIEW*/}
                      {
                        item.reply.map((reply, i) => (
                        <Box display="flex" flexDirection="row" gap={"20px"} mb={2} pl={8} key={i}>
                          <Avatar alt={reply.commentedBy.name[0]} src={reply.commentedBy.displayPic} />
                          <div className={classes.comment}>
                            <div className={classes.commentText}>
                              <div>
                                {reply?.commentedBy?.name} • {moment(reply.createdAt).startOf('sec').fromNow()}
                              </div>
                              { reply.replyUserId !== null && 
                                <div style={{ color: 'blue'}}>
                                  Replying to @{reply.replyUserId.name}
                                </div>
                              }
                              {reply.text}
                            </div>
    
                            <div className={classes.commentLike}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {
                                  reply?.likes?.find(id => id === userId)
                                  ?
                                    <FavoriteIcon sx={{ cursor: "pointer"}} onClick={() => likeHandler(item._id, "Reply", reply._id)} /> 
                                  :
                                    <FavoriteBorderIcon sx={{ cursor: "pointer"}} onClick={() => likeHandler(item._id, "Reply", reply._id)} /> 
                                }
                                <div style={{ width: "20px"}}>{reply?.likes?.length}</div>
                              </div>
                              <div style={{ cursor: "pointer" }} className={classes.commentReply} 
                                onClick={() => {setOpenReplyCmt(true); setReplyToReplyCommentId(reply._id);}}> 
                                Reply
                              </div>
                            </div>
                            {/* REPLY COMMENT */}
                            {
                              openReplyCmt && replyToReplyCommentId === reply._id
                              &&
                              <TextField 
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                fullWidth
                                multiline
                                maxRows={4}
                                size='small'
                                label="Reply" 
                                placeholder="Have something to comment?"
                                value={replyComment}
                                onChange={(e) => setReplyComment(e.target.value)} 
                                InputProps={{endAdornment: 
                                  commenting
                                  ?
                                    <CircularProgress color="secondary" size={"25px"} />
                                  :
                                  <IconButton onClick={() => submitReplyToReply(item._id, reply.commentedBy._id)} sx={{marginTop: "auto", padding: "0px", visibility: replyComment.length > 0 ? "visible": "hidden"}} color='primary'>
                                    <SendIcon />
                                  </IconButton>
                                }}/>
                            }
                          </div>
                        </Box>
                        ))
                      }
                    </div>
                  ))
                }

              </Box>
            </Box>
           
          </Grid>

          {/* Side card */}
          <Grid item 
            className={classes.sideBar} 
            xs={12}
            sm={12}
            md={4}
            lg={3.6}
            xl={3.5}>

            {/* Top Section */}
            <Box className={classes.sideBarBox}>
              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                {
                  loading
                  ?
                  <Skeleton variant="circular" width={40} height={40} />
                  :
                  <Avatar alt={articleObj?.author?.name[0]} src={articleObj?.author?.displayPic}/>
                }
                {
                  loading
                  ?
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  :
                  <Typography variant='logoSub'>{articleObj?.author?.name}</Typography>
                }
              </Box>
              <Box mt={2} mb={2}>
                {
                  loading
                  ?
                  <Skeleton variant="rectangular" width={"100%"} height={36.5} />
                  :
                  <Button variant='contained' disableElevation sx={{ color: "#fff"}} fullWidth>Follow</Button>
                }
              </Box>
              <Box mt={2} mb={2}>
                Tag Line
              </Box>
              <Box mt={2} mb={2}>
                <b>JOINED</b>
                <Box>
                  <Typography>{new Date(articleObj?.author?.createdAt).toDateString().slice(4).substring(0, 6) + ", "+ new Date(articleObj?.author?.createdAt).toDateString().slice(4).substring(6, 11)|| ""}</Typography>
                </Box>
              </Box>
            
            </Box>
            <Box className={classes.sideBarBox}>
              <Box >
                <Typography variant='logoSub'>More by </Typography><Typography variant='logoSub' color="secondary">{articleObj?.author?.name}</Typography>
              </Box>
              <Divider sx={{ margin: "16px -16px 10px -16px" }}/>
              {
                loadingMore
                ?
                <Skeleton variant="rectangular" width={"100%"} height={150} />
                :
                moreArticle.map((item, index) => (
                  <Box key={index} sx={{ cursor: "pointer" }}>
                    <Typography sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        "&:hover": {
                          color: "#19857b"
                        }
                      }}
                      onClick={() => navigate(`/${item.slug}`)}>{item.title}</Typography>
                      <Box>
                        {item.tags.map((tag, i) => (
                          <span key={i} style={{marginRight: "10px", fontSize: "13px"}}>
                          { i < 3 ? <i><u><span style={{ padding: "5px" }}>#{tag}</span></u></i> : <></> }
                          </span>
                        ))}
                      </Box>
                      {index < moreArticle.length-1 && <Divider sx={{ margin: "10px -16px 10px -16px" }}/>}
                  </Box>
                ))
              }
            </Box>
            {
              user === null
              ?
              <Box className={classes.sideBarBox} display={'flex'} justifyContent={'center'} fullWidth mt={2} mb={2}>
                <Typography variant='logoSub'>Login to like article</Typography>
              </Box>
              :
              <Box className={classes.sideBarBox} display='flex' justifyContent="space-evenly" alignItems="center"> 
                <Tooltip title="Like">
                  <IconButton>
                    <ThumbUpAltTwoToneIcon sx={{ fontSize: "30px" }} color='secondary'/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Jump to Comments">
                  <IconButton>
                    <ChatBubbleTwoToneIcon sx={{ fontSize: "28px" }}  color='secondary'/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Bookmark this Article">
                  <IconButton>
                    <BookmarkTwoToneIcon sx={{ fontSize: "28px" }}  color={bookmark.checkBookmark ? 'secondary' : ''} 
                      onClick={handleBookmark}/>
                  </IconButton>
                </Tooltip>
              </Box>
            }

          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ReadPage;